<template>
    <div>
<!--        <div class="main-content-header">-->
<!--            <div>-->
<!--                <h4><strong>Recipients</strong></h4>-->
<!--            </div>-->
<!--        </div>-->

        <div v-if="selfRecipients.length > 0 || anotherRecipients.length > 0">
            <div v-if="selfRecipients.length > 0">
                <h4 class="mt-2 mb-2"><strong>Your bank accounts</strong></h4>
                <div class="row" v-for="item in selfRecipients" :key="item.receiverKey" @click="expandRecipients(item)">
                    <div class="col-md-8 col-lg-8 col-xs-12 col-sm-12">
                        <div class="row bg-white p-2 mb-2 mr-2 ml-1 pointer">
                            <h4><strong>{{item.name}}</strong></h4>
                            <h6>{{item.bank_acct_ccy}} account ending in
                                {{last5DigitsBankAccountNumber(item.bank_acct_num)}}</h6>

                            <div v-if="item.visible">
                                <hr>
                                <div class="row mt-2">
                                    <div class="col-lg-6 col-md-6">
                                        <h6>
                                            <small><strong>Account Holder Name</strong></small>
                                        </h6>
                                        <h5>
                                            {{item.acct_holder_name}}
                                        </h5>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <h6>
                                            <small><strong>Bank Name</strong></small>
                                        </h6>
                                        <h5>
                                            {{item.bank_name}}
                                        </h5>
                                    </div>

                                </div>
                                <div class="row mt-2">
                                    <div class="col-lg-6 col-md-6">
                                        <h6>
                                            <small><strong>Account Number</strong></small>
                                        </h6>
                                        <h5>
                                            {{item.bank_acct_num}}
                                        </h5>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <h6>
                                            <small><strong>Swift Code / ABA Routing Number</strong></small>
                                        </h6>
                                        <h5>
                                            {{item.bank_swift}}
                                        </h5>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div v-if="anotherRecipients.length > 0">
                <h4 class="mt-2 mb-2"><strong>Your recipients</strong></h4>
                <div class="row" v-for="(item,index) in anotherRecipients" :key="index" @click="expandRecipients(item)">
                    <div class="col-md-8 col-lg-8 col-xs-12 col-sm-12">
                        <div class="row bg-white p-2 mb-2 mr-2 ml-1 pointer">
                            <h4><strong>{{item.name}}</strong></h4>
                            <h6>{{item.bank_acct_ccy}} account ending in
                                {{last5DigitsBankAccountNumber(item.bank_acct_num)}}</h6>

                            <div v-if="item.visible">
                                <hr>
                                <div class="row mt-2">
                                    <div class="col-lg-6 col-md-6">
                                        <h6>
                                            <small><strong>Account Holder Name</strong></small>
                                        </h6>
                                        <h5>
                                            {{item.acct_holder_name}}
                                        </h5>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <h6>
                                            <small><strong>Bank Name</strong></small>
                                        </h6>
                                        <h5>
                                            {{item.bank_name}}
                                        </h5>
                                    </div>

                                </div>
                                <div class="row mt-2">
                                    <div class="col-lg-6 col-md-6">
                                        <h6>
                                            <small><strong>Account Number</strong></small>
                                        </h6>
                                        <h5>
                                            {{item.bank_acct_num}}
                                        </h5>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <h6>
                                            <small><strong>Swift Code / ABA Routing Number</strong></small>
                                        </h6>
                                        <h5>
                                            {{item.bank_swift}}
                                        </h5>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <div class="row" v-else>
            <h5 class="m-2">No recipients found.</h5>
        </div>


    </div>


</template>
<script>
    import {mapGetters} from 'vuex';

    export default {
        data() {
            return {
                recipients: [],
                toggleRecipients: false,
                selfRecipients: [],
                anotherRecipients: [],

                debugConsole: false,
            }
        },
        computed: {
            ...mapGetters(["userDetailsGetter"]),


        },
        metaInfo: {
            title: 'TransCrypt - Dashboard Recipients | Send Money Faster with Cryptocurrency',
        },
        mounted() {
            if (this.debugConsole) console.log("Dashboard recipients Mounted");

            // this.recipients = this.userDetailsGetter && this.userDetailsGetter.recipients.filter(el=> el.acct_holder_name !== null);
            // console.log("Recipients", this.recipients);
            this.selfRecipients = this.userDetailsGetter && this.userDetailsGetter.recipients.filter(el => el.receiver_type === "self" && el.acct_holder_name !== null);
            if (this.debugConsole) console.log("Self Recipients", this.selfRecipients);

            this.anotherRecipients = this.userDetailsGetter && this.userDetailsGetter.recipients.filter(el => el.receiver_type === "another" && el.acct_holder_name !== null);
            if (this.debugConsole) console.log("Another Recipients", this.anotherRecipients);
        },

        methods: {
            expandRecipients(item) {
                if (this.debugConsole) console.log("Item", item);

                let payload = {};
                payload.receiverKey = item.receiver_key;
                payload.visible = !item.visible;

                this.$store.commit("EXPAND_RECIPIENT", payload)
            }
        },

    }

</script>